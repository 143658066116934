import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { Container, PrimaryButton } from "../../styles/ui";
import { Link } from "gatsby";

import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { useScrollTrigger } from "../../lib/useScrollTrigger";

import MockupHullam from "../../images/svg/mockup-hullam.svg";
import { css } from "@emotion/react";

// import { useInView } from "react-cool-inview";

const Mockup: React.FC = () => {
  const lightScrollTrigger = {
    id: "lightScrollTrigger",
    trigger: "#mockup",
    start: "bottom bottom",
    end: "bottom+=150% bottom",
    scrub: 0.5,
    pin: true,
    anticipatePin: 1,
    invalidateOnRefresh: true,
    // markers: true,
  };

  const textEnterScrollTrigger = {
    id: "textEnterScrollTrigger",
    trigger: "#mockup",
    start: "center bottom",
    end: "bottom bottom",
    scrub: true,
    invalidateOnRefresh: true,
  };

  useEffect(() => {
    // Background color change effect
    gsap.fromTo(
      "#mockup",
      { backgroundColor: "#000" },
      {
        backgroundColor: "#fff",
        scrollTrigger: lightScrollTrigger,
      }
    );
    // mix-blend-mode effect
    gsap.fromTo(
      "#title",
      { color: "#fff" },
      {
        color: "#000",
        scrollTrigger: lightScrollTrigger,
      }
    );
    // text enter effect
    gsap.fromTo(
      "#title",
      { opacity: 0 },
      {
        opacity: 1,
        // ease: "none",
        scrollTrigger: textEnterScrollTrigger,
      }
    );
    return () => {
      ScrollTrigger.getById("lightScrollTrigger")?.kill();
      ScrollTrigger.getById("textEnterScrollTrigger")?.kill();
    };
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, []);

  return (
    <Wrapper>
      <Inner id="mockup">
        <MockupHullam
          css={css`
            position: absolute;
            /* z-index: 9999; */
            width: 100%;
          `}
        />
        <div>
          <StaticImage
            src="../../images/mockup.png"
            alt="Mockup"
            objectFit="contain"
          />
        </div>
        <div>
          <Title id="title">
            {/* <span>Lépj ki a fényre</span> az új honlapoddal! */}
            Elkészítjük a <span>honlapot</span>,<br />
            amit megálmodtál!
          </Title>
        </div>
      </Inner>
      {/* <div style={{ background: "pink", width: "100%", height: "50vh" }}></div> */}
    </Wrapper>
  );
};

export default Mockup;

const Wrapper = styled.section`
  /* border: 1px solid red; */
  margin-bottom: 0;
  overflow: hidden;
  /* padding-bottom: -2px; */
  /* overflow: hidden; */
  background-color: white;
  position: relative;
  color: white;
  width: 100%;

  outline: none;
  border: none;
  padding: 0;
  margin: 0;

  /* &:after {
    content: "";
    display: block;
    width: 100%;
    height: 5px;
    background-color: white;
  } */
`;

const Inner = styled(Container)`
  /* overflow: hidden; */
  position: relative;
  height: 120vh;
  background-color: #000;
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 0 auto;
  column-gap: 32px;

  div {
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
  }

  & > div:first-of-type {
    align-items: flex-end;
    padding-bottom: 25vh;
  }

  @media (max-width: 1069px) {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;

    h2 {
      align-self: flex-start;
    }
  }
`;

const Title = styled.h2`
  @media (min-width: 320px) {
    font-size: calc(2rem + (50 - 32) * ((100vw - 320px) / (1920 - 320)));
    min-height: 0vw;
  }
  @media (min-width: 1920px) {
    font-size: 3.125rem;
  }
`;
