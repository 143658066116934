import * as React from "react";
import Hero from "../components/weboldal/hero";
import Mockup from "../components/weboldal/mockup";
import Contact from "../components/contact";
import Layout from "../components/layout";
// import IsmerjMegMinket from "../components/weboldal/ismerjMegMinket";
import IsmerjMegMinket from "../components/weboldal/ismerjMegMinket";
import Referenciak from "../components/weboldal/referenciak";

export const Weboldal: React.FC = () => {
  return (
    <Layout
      allowTransparentHeader={true}
      variant="weboldal"
      title="Weboldal"
      description="Weboldal kezdőoldal"
    >
      <Hero />
      <Mockup />
      <Referenciak />
      <IsmerjMegMinket />
      <Contact variant="marketing" />
    </Layout>
  );
};

export default Weboldal;
