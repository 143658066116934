import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import useMeasure from "react-use-measure";
import { ResizeObserver } from "@juggle/resize-observer";
import mergeRefs from "react-merge-refs";
import { v4 as uuidv4 } from "uuid";

export const useScrollTrigger: (scrollTriggerVars: ScrollTrigger.Vars) => {
  ref: (instance: HTMLElement | SVGElement | null) => void;
  triggerRef: React.MutableRefObject<HTMLElement | SVGElement | null>;
  id: string;
} = (scrollTriggerVars) => {
  const [measureRef, bounds] = useMeasure({ polyfill: ResizeObserver });
  const triggerRef = useRef(null);
  const ref = mergeRefs([triggerRef, measureRef]);
  const id = "ScrollTrigger" + uuidv4();

  useEffect(() => {
    ScrollTrigger.create({
      ...scrollTriggerVars,
      id: id,
      trigger: triggerRef.current,
    });
    return () => {
      ScrollTrigger.getById(id)?.kill();
    };
  }, []);

  useEffect(() => {
    ScrollTrigger.refresh();
  }, [bounds]);

  return { ref, triggerRef, id };
};
