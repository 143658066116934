import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { StaticImage } from "gatsby-plugin-image";
import { Container, PrimaryButton } from "../../styles/ui";
import { Link } from "gatsby";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { useScrollTrigger } from "../../lib/useScrollTrigger";

const Referenciak: React.FC = () => {
  const stvars: ScrollTrigger.Vars = {
    start: "top bottom",
    end: "top center",
    scrub: 0.5,
    invalidateOnRefresh: true,
  };

  const blueST = useScrollTrigger(stvars);
  const titleST = useScrollTrigger(stvars);

  useEffect(() => {
    gsap.fromTo(
      blueST.triggerRef.current,
      { yPercent: 50, opacity: 0 },
      {
        yPercent: 0,
        opacity: 1,
        scrollTrigger: ScrollTrigger.getById(blueST.id)?.vars,
      }
    );
    gsap.fromTo(
      titleST.triggerRef.current,
      { yPercent: 100, opacity: 0 },
      {
        yPercent: 0,
        opacity: 1,
        scrollTrigger: ScrollTrigger.getById(titleST.id)?.vars,
      }
    );
  }, []);

  return (
    <Wrapper>
      <BlueDiv ref={blueST.ref} />
      <TitleContainer>
        <div ref={titleST.ref}>
          {/* <h2>Merülj el benne és éld át</h2> */}
          {/* <h2>Lépj ki a fényre a vállalkozásoddal!</h2> */}
          <h1>
            <span>Referenciák</span>
          </h1>
        </div>
      </TitleContainer>
      <Grid>
        <div>
          <a
            href="https://fastrrr.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ all: "unset" }}
          >
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/weboldalak/fastrrr.jpg"
              alt="Fastrrr"
            />
            <Overlay>
              <h4>Fastrrr.com</h4>
            </Overlay>
          </a>
        </div>
        <div>
          <a
            href="https://vulpexkft.hu"
            target="_blank"
            rel="noopener noreferrer"
            style={{ all: "unset" }}
          >
            <StaticImage
              style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
              imgStyle={{ borderRadius: "20px" }}
              src="../../images/weboldalak/vulpex.jpg"
              alt="Vulpex Kft."
            />
            <Overlay>
              <h4>Vulpex.hu</h4>
            </Overlay>
          </a>
        </div>
        {/* <div>
          <StaticImage
            style={{ borderRadius: "20px", boxShadow: "var(--shadow)" }}
            src="../../images/weboldalak/vulpex.jpg"
            alt="Vulpex Kft."
          />
          <Overlay>
            <h4>Vulpex.hu</h4>
          </Overlay>
        </div> */}
      </Grid>
      <More>
        <Link to="/weboldal/referenciak">
          <PrimaryButton>TOVÁBBI REFERENCIÁK</PrimaryButton>
        </Link>
      </More>
    </Wrapper>
  );
};

export default Referenciak;

const Wrapper = styled.section`
  /* padding-top: -0; */
  overflow: hidden;
  position: relative;
  color: white;
  width: 100%;
`;

const Grid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  margin: 0 auto;
  column-gap: 32px;
  row-gap: 64px;
  background: white;
  & > div {
    position: relative;
    overflow: hidden;
    /* height: 300px; */
    border-radius: 20px;
    box-shadow: var(--shadow);
  }

  @media (max-width: 440px) {
    grid-template-columns: 1fr;
  }
`;

const Overlay = styled.div`
  cursor: pointer;
  background: rgba(14, 14, 14, 0.7);
  position: absolute;
  inset: 0;
  display: grid;
  place-items: center;
  border-radius: 20px;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
  transition: 0.5s;
`;

const TitleContainer = styled(Container)`
  position: relative;
  /* background: linear-gradient(180deg, #000000 0%, hsla(0, 0%, 8%, 1) 100%); */
  background: white;
  padding-top: 192px;
  padding-bottom: 128px;
  text-align: center;
  h1 {
    margin-top: 0;
  }
  h2 {
    font-weight: 400;
  }
`;

const More = styled(Container)`
  background-color: white;
  padding: 64px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BlueDiv = styled.div`
  z-index: 999;
  position: absolute;
  width: 4px;
  height: 120px;
  background: var(--color-primary);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
`;
