import React, { useState, useEffect } from "react";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { StaticImage } from "gatsby-plugin-image";
import ShiftBy from "../../lib/shiftBy";
import { H1, PrimaryButton } from "../../styles/ui";
import useMediaQuery from "@mui/material/useMediaQuery";
import useScrollTo from "../../lib/scrollto";

import { animated, useSpring, config } from "react-spring";

import useBoop from "../../lib/boop";

import { motion } from "framer-motion";

const Hero: React.FC = () => {
  const verticalHero = useMediaQuery("(max-width:1050px)");
  const { scrollTo } = useScrollTo();

  // title enter from left
  // const [titleStyle, api] = useSpring(() => ({
  //   from: {
  //     opacity: 0,
  //     transform: "translateX(-20px)",
  //   },
  //   to: {
  //     transform: "translateX(0%)",
  //     opacity: 1,
  //     delay: 1000,
  //     config: {
  //       ...config.molasses,
  //     },
  //   },
  // }));
  const [titleStyle, api] = useSpring(() => ({
    opacity: 0,
    transform: "translateX(-20px)",
  }));

  useEffect(() => {
    api.start({
      transform: "translateX(0%)",
      opacity: 1,
      delay: 1000,
      config: {
        ...config.molasses,
        precision: 0.05,
      },
    });
  }, []);

  // const [titleHovered, setTitleHovered] = useState<boolean>(false);

  // const titleHoverStyle = useSpring({
  //   textShadow: titleHovered ? "0 0 10px currentColor" : "0 0 0px currentColor",
  // });

  const titleVariants = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    reveal: {
      opacity: 1,
      x: 0,
      transition: {
        delay: 1,
      },
    },
  };

  return (
    <Wrapper>
      <Left>
        <div>
          <Title
            initial="hidden"
            animate="reveal"
            variants={titleVariants}
            whileHover={{ textShadow: "0 0 10px currentColor" }}
            // style={{ ...titleStyle, ...titleHoverStyle }}
            // onMouseEnter={() => setTitleHovered(true)}
            // onMouseLeave={() => setTitleHovered(false)}
          >
            {/* Adj <span>élményt</span> a honlapoddal! */}
            {/* Adj át egy <span>élményt</span> felhasználóidnak! */}
            Lépj ki a fényre az új honlapoddal!
          </Title>
        </div>
        {/* <p>Lépj ki a fényre a vállalkozásoddal!</p> */}
        {/* <p>Elkészítjük álmaid weblapját!</p> */}
        <br />
        <PrimaryButton onClick={() => scrollTo("#contact")}>
          {/* ÚJ HONLAPOT SZERETNÉK */}
          AJÁNLATOT KÉREK
        </PrimaryButton>
      </Left>
      <Right>
        <div
          css={css`
            pointer-events: none;
            position: absolute;
            z-index: 2;
            top: 0;
            left: ${verticalHero ? "0" : "-30%"};
            width: ${verticalHero ? "100%" : "120%"};
            height: 100%;
            display: grid;
            place-items: center;
          `}
        >
          <StaticImage
            src="../../images/darkhero3.jpg"
            alt="Hero"
            objectFit="contain"
            placeholder="dominantColor"
          />
        </div>
      </Right>
      <FollowUs>
        <a
          href="https://www.facebook.com/compassmarketing.agency2020/"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillFacebook />
        </a>
        <a
          href="https://www.instagram.com/compassmarketing.agency"
          target="_blank"
          rel="noreferrer"
        >
          <AiFillInstagram />
        </a>
        <div />
        <ShiftBy y={2}>
          <p>KÖVESS MINKET</p>
        </ShiftBy>
      </FollowUs>
    </Wrapper>
  );
};

export default Hero;

// const Title = styled(animated.h1)`
const Title = styled(motion.h1)`
  @media (min-width: 320px) {
    font-size: calc(2.25rem + (60 - 36) * ((100vw - 320px) / (1920 - 320)));
    min-height: 0vw;
  }
  @media (min-width: 1920px) {
    font-size: 3.75rem;
  }
`;

const Wrapper = styled.section`
  overflow-x: hidden;
  position: relative;
  background: black;
  color: white;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "txt img";

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "img"
      "txt";
    height: auto;
  }
`;

const Left = styled.div`
  grid-area: txt;
  padding: 100px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 1050px) {
    height: auto;
    max-width: 1920px;
    padding: 100px var(--gutter);
    margin: auto;
    padding-top: 32px;
  }
`;

const Right = styled.div`
  grid-area: img;
  width: 100%;
  height: 100%;
  position: relative;
  @media (max-width: 1050px) {
    height: 800px; // fallback
    height: 70vw;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 60px;
  }
`;

const FollowUs = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  align-items: center;
  right: 0;
  top: 50vh;
  * {
    margin-right: 10px;
  }
  svg {
    width: 22px;
    height: 22px;
    transform: rotate(-90deg);
    fill: white;
  }
  div:first-of-type {
    width: 60px;
    height: 1px;
    background: white;
  }
  p {
    font-size: 1rem;
    color: white;
  }
  transform-origin: right top;
  transform: rotate(90deg) translate(50%, 100%);

  @media (max-width: 520px) {
    display: none;
  }
`;
